<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 0rem !important;">

        <!-- Filtros de busqueda -->
        <div class="filtros">
            <div class="filtros" v-for="(filter, index) in usoDeFiltros" :key="index">
                <v-text-field v-if="filter === 'filtro1'" class="me-2" outlined dense hide-details :label="filtro1Label"
                    @keypress="validarLetra($event)" v-model="buscador1" @keyup="listarProductos">
                </v-text-field>
                <v-text-field v-if="filter === 'filtro2'" outlined dense hide-details :label="filtro2Label"
                    v-model="buscador2" @keyup="listarProductos">
                </v-text-field>
            </div>
        </div>

        <!-- Tabla para mostrar los productos buscados en los filtros -->
        <v-data-table :loading="loading" fixed-header :headers="filteredHeaders" :items="listaDeRelaciones"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            v-model="productoSeleccionado" class="mt-2 estiloFilas" height="32vh" :footer-props="footerPropsTabla"
            :single-select="singleSelect" show-select>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>
                            <v-checkbox v-model="productoSeleccionado" :value="item"
                                @change="handleCheckboxChange(item)"></v-checkbox>
                        </td>
                        <td v-for="header in filteredHeaders" :key="header.field">{{ item[header.field] }}</td>
                    </tr>
                    <tr v-if="calcularNoHayEscritura() && !noHayResultadosBusqueda">
                        <td :colspan="filteredHeaders.length + 1" class="mensaje">
                            <span><v-icon class="icono"> feedback </v-icon></span>
                            <div>
                                <em>No hay registros, utilice los filtros para buscar un producto.</em>
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="noHayResultadosBusqueda">
                        <td :colspan="filteredHeaders.length + 1" class="mensaje">
                            <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
                            <div>
                                <em style="color: #ff5252;">
                                    No se encontraron resultados para la búsqueda, ingrese un nuevo valor válido.
                                </em>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- Mensaje de error si la bodega y el producto selecionado no existe en la tabla IN_PRBOD de STONE -->
        <div v-if="datosExistentesError !== '' && !campoBodegaVacio" class="mensaje-error">
            <span class="error-texto">{{ datosExistentesError }}</span>
        </div>

        <!-- Mensaje de error si la bodega y el producto selecionado no existe en la tabla HD_PRBOD de STONE -->
        <div v-if="datosErrorRelacion && productoSeleccionado.length > 0  && !campoBodegaVacio" class="mensaje-error">
            <span class="error-texto">{{ datosErrorRelacion }}</span>
        </div>

        <!-- Mensaje de error si el producto selecionado esta inactivo en la tabla IN_MAITE de STONE -->
        <div class="mensaje-error error-texto" v-if="mensajeVerificacion && mensajeVerificacionOculto">
            {{ mensajeVerificacion }}
        </div>

        <!-- Mensaje de error si el producto no cumple las condiciones para parametrizar en WASolutions -->
        <div v-if="mensajeError && esconderMensajeError" class="mensaje-error">
            <span class="error-texto">{{ mensajeError }}</span>
        </div>

        <!-- Contenedor para mostrar los mensajes de error -->
        <div v-if="mostrarNotificacion" class="notificacion advertencia">
            <span><v-icon color="#f80505" class="rotate-animation-notification size">highlight_off</v-icon></span>
            <span>{{ mensajeNotificacion }} <br> {{ pathNotificacion }}</span>
        </div>

    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
export default {
    // Props para manejar la comunicación con el componente padre
    props: {
        headers: {
            type: Array,
        },
        usoDeHeaders: {
            type: Array,
            default: () => []
        },
        usoDeFiltros: {
            type: Array,
            default: () => []
        },
        filtro1Label: {
            type: String,
            default: 'Código de producto'
        },
        filtro2Label: {
            type: String,
            default: 'Nombre del producto'
        },
        itemSeleccionado: {
            type: Object,
            default: null
        },
        datosExistentesError: {
            type: String,
            default: ''
        },
        datosErrorRelacion: {
            type: String,
            default: ''
        },
        campoBodegaVacio: {
            type: Boolean,
        },
    },

    data() {
        return {
            singleSelect: true,
            productoSeleccionado: [],
            listaDeRelaciones: [],
            pagina: 1,
            totalPaginas: 0,
            itemsPorPagina: 10,
            loading: false,
            buscador1: '',
            buscador2: '',
            noHayResultadosBusqueda: false,
            itemCodProd: {},
            itemNomProd: {},
            checkboxSeleccionado: true,
            mensajeError: '',
            esconderMensajeError: false,
            mensajeVerificacion: '',
            mensajeVerificacionOculto: false,
            seleccionAnterior: [],
            defaultHeaders: [
                { text: 'CÓDIGO DEL PRODUCTO', field: 'codProd', width: "14%", align: 'left', sortable: false },
                { text: 'NOMBRE DEL PRODUCTO', field: 'nomProd', width: "76%%", align: 'left', sortable: false },
            ],
            footerPropsTabla: {
                'items-per-page-options': [10, 15, 20, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            mostrarNotificacion: false,
            mensajeNotificacion: "",
            pathNotificacion: "",
        };
    },

    // Al cargar el componente, se llama a la función listarProductos()
    created() {
        this.listarProductos();
    },

    watch: {
        // Si hay cambios en la variable "pagina", se llama a la función listarProductos()
        pagina: function () {
            this.listarProductos();
        },

        // Si hay cambios en la variable "itemsPorPagina", se actualiza la variable "pagina" a 1 y se llama a la función listarProductos()
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarProductos();
        },
        // Si hay cambios en la variable "buscador1", se realiza una serie de acciones, incluyendo llamar a la función listarProductos()
        'buscador1': function () {
            // Si hay elementos seleccionados en el array "productoSeleccionado", se limpia el array y se emite un evento con valor nulo
            this.productoSeleccionado = [];
            this.$emit('busqueda-cambiada', true);
            if (this.productoSeleccionado.length > 0) {
                this.productoSeleccionado = [];
                this.$emit('item-productoSeleccionado', null); // Emitir evento con valor nulo para indicar deselección
            }
            this.pagina = 1;
            this.mensajeVerificacionOculto = false;
            this.esconderMensajeError = false;
            this.listarProductos();
        },
        // Si hay cambios en la variable "buscador2", se realiza una serie de acciones, incluyendo llamar a la función listarProductos()
        'buscador2': function () {
            // Si hay elementos seleccionados en el array "productoSeleccionado", se limpia el array y se emite un evento con valor nulo
            this.productoSeleccionado = [];
            this.$emit('busqueda-cambiada', true);
            if (this.productoSeleccionado.length > 0) {
                this.productoSeleccionado = [];
                this.$emit('item-productoSeleccionado', null);  // Emitir evento con valor nulo para indicar deselección
            }
            this.pagina = 1;
            this.mensajeVerificacionOculto = false;
            this.esconderMensajeError = false;
            this.listarProductos();
        },
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),

        filteredHeaders() {
            // Filtra los headers según los que se deseen utilizar
            if (this.usoDeHeaders.length !== 0) {
                return this.defaultHeaders.filter((header) => this.usoDeHeaders.includes(header.text));
            } else {
                return this.defaultHeaders;
            }
        },

        // Indica si hay un error de validación que se hace en la consulta a la tabla IN_PRBOD de STONE
        hasValidationError() {
            return this.datosExistentesError !== '';
        },

        // Indica si hay un error de validación que se hace en la consulta a la tabla HD_PRBOD de STONE
        hasValidationError2() {
            return this.datosErrorRelacion !== ''; 
        },
    },

    methods: {

        /**
        * Método para listar productos.
        * Realiza una llamada HTTP al backend para obtener una lista de productos según los parámetros de búsqueda.
        */
        listarProductos() {
            this.loading = true;
            const registrosSeleccionados = this.productoSeleccionado.slice(); // Copia de los registros seleccionados actuales
            if (this.buscador1 === '' && this.buscador2 === '') {
                // Si los buscadores están vacíos, se vacían las listas y se realiza una serie de acciones
                this.listaDeRelaciones = [];
                this.loading = false;
                this.totalPaginas = 0;
                this.noHayResultadosBusqueda = false;
                return;
            }
            this.$http
                .get(`msa-administration/inMaite/listarProductos`, {
                    params: {
                        page: this.pagina - 1,
                        size: this.itemsPorPagina,
                        codProd: `${this.buscador1}`,
                        nomProd: `${this.buscador2.toLowerCase()}`,
                        codEmpr: this.enterprise.code,
                    },
                })
                .then(response => {
                    this.listaDeRelaciones = response.data.content;
                    // Actualizar el número total de páginas
                    this.totalPaginas = response.data.totalElements;
                    if (response.data.content.length === 0) {
                        this.noHayResultadosBusqueda = true;
                    } else {
                        this.noHayResultadosBusqueda = false;
                    }
                    // Volver a seleccionar los registros previamente seleccionados
                    this.productoSeleccionado = registrosSeleccionados.filter(registro => {
                        return this.listaDeRelaciones.some(item => item.rmtPrbo === registro.rmtPrbo);
                    });
                    this.loading = false;
                }).catch(error => {
                    this.verError(error);
                    this.loading = false;
                });
        },

        /**
        * Manejo de la busqueda en los filtros de la tabla.
        * Emite un evento cuando se cambia la busqueda en los filtros.
        */
        manejoBusquedaCambiada() {
            this.$emit('busqueda-cambiada', true);
        },

        /**
        * Maneja el cambio de estado del checkbox.
        * Actualiza los registros seleccionados y realiza acciones en función del estado del checkbox.
        * @param {Object} item - Registro seleccionado.
        */
        handleCheckboxChange(item) {

            // Desmarcar todos los registros excepto el seleccionado
            if (item) {
                this.productoSeleccionado = [item];
                this.itemCodProd = item.codProd;
                this.itemNomProd = item.nomProd;
                this.$emit('item-productoSeleccionado', item);
                this.esconderMensajeError = false;
                this.mensajeVerificacionOculto = false;

            } else {
                this.productoSeleccionado = [];
                this.$emit('item-productoSeleccionado', null);
                this.valorSeleccionado = null;
            }
            this.hayElementosSeleccionados = this.productoSeleccionado.length > 0;

            // Actualizar el estado anterior de los registros seleccionados
            this.seleccionAnterior = this.productoSeleccionado.slice();

            this.verificarEstadoProducto();
            this.validacion();
        },

        /**
        * Verifica el estado del producto seleccionado.
        * Realiza una llamada al backend para verificar el estado del producto y muestra un mensaje en consecuencia.
        */
        verificarEstadoProducto() {
            const codProdString = this.itemCodProd.toString();
            const nomProdString = this.itemNomProd.toString();

            // Realizar la llamada al método de verificación del estado del producto en el backend

            this.$http
                .get('msa-administration/inMaite/validarEstado', {
                    params: {
                        codProd: codProdString,
                        nomProd: nomProdString,
                        codEmpr: this.enterprise.code,
                    },
                })
                .then((response) => {
                    const resultado = response.data;
                    if (resultado === 'El producto esta inactivo') {
                        this.mostrarMensaje('El producto está inactivo');
                        this.mensajeVerificacionOculto = true;
                    } else {
                        this.mostrarMensaje('');
                        this.mensajeVerificacionOculto = false;
                    }
                })
                .catch((error) => {
                    console.error('Error en la verificación del estado del producto:', error);
                    this.mensajeVerificacionOculto = true;
                });
        },

        /**
        * Muestra un mensaje de verificación.
        * Actualiza el mensaje de verificación y emite eventos correspondientes.
        * @param {string} mensaje - Mensaje de verificación a mostrar.
        */
        mostrarMensaje(mensaje) {
            this.mensajeVerificacion = mensaje;
            if (mensaje) {
                this.$emit('mostrar-mensaje-error');
            } else {
                this.$emit('ocultar-mensaje-error');
            }
        },

        /**
        * Realiza la validación del formulario.
        * Verifica las condiciones para relacionar el producto y muestra un mensaje de error si no se cumplen.
        */
        validacion() {
            const codProdString = this.itemCodProd.toString();
            this.$http
                .get("msa-administration/inMaadi/validarRequisitos", {
                    params: {
                        codProd: codProdString,
                        codEmpr: this.enterprise.code,
                    }
                })
                .then((response) => {
                    const condicion = response.data;
                    if (condicion === "Producto no parametrizado para WA Solutions") {
                        this.mensajeWaSolution('Producto no parametrizado para WA Solutions');
                        this.esconderMensajeError = true;
                    } else {
                        this.mensajeWaSolution('');
                        this.esconderMensajeError = false;
                    }
                })
                .catch((error) => {
                    this.verError(error);
                    this.esconderMensajeError = true;
                });
        },

        /**
        * Muestra un mensaje de verificación.
        * Actualiza el mensaje de verificación y emite eventos correspondientes.
        * @param {string} mensaje - Mensaje de verificación a mostrar.
        */
        mensajeWaSolution(respuesta) {
            this.mensajeError = respuesta;
            if (respuesta) {
                this.$emit('mostrar-respuesta-error');
            } else {
                this.$emit('ocultar-respuesta-error');
            }
        },

        /**
        * Calcula si no hay escritura en los campos de búsqueda.
        * @returns {boolean} - Valor que indica si no hay escritura en los campos de búsqueda.
        */
        calcularNoHayEscritura() {
            return this.buscador1 === '' && this.buscador2 === '';
        },

        /**
        * Valida la tecla presionada en un evento de teclado.
        * Permite solo números y caracteres especiales en el campo de búsqueda.
        * @param {Event} event - Evento de teclado.
        */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.toLowerCase() !== "e" && teclaPresionada !== "-" && teclaPresionada !== "+" && teclaPresionada !== ",") {
                const regex = /[0-9$]/g;
                if (!regex.test(teclaPresionada)) {
                    event.preventDefault();
                }
            } else {
                event.preventDefault();
            }
        },

        /**
        * Limpia el formulario y los mensajes de error.
        */
        limpiar() {
            this.mostrarMensaje('');
            this.mensajeWaSolution('');
        },

        /**
         * Cierra la notificación del error
         */
        cerrarNotificacion() {
            this.mostrarNotificacion = false;
            this.mensajeNotificacion = "";
            this.pathNotificacion = "";
            this.tipoNotificacion = "";
        },

        /**
         * Muestra el error en una notificación correspondiente.
         * 
         * @param {*} error - Objeto de error.
         */
        verError(error) {
            
            let errorMessage = "Error desconocido, contacta con el administrador.";
            let errorPath = "";

            if (error.response && error.response.data) {
                if (error.response.data.mensaje && Array.isArray(error.response.data.mensaje)) {
                    errorMessage = error.response.data.mensaje[0];
                } else if (error.response.data.error) {
                    errorMessage = error.response.data.error;
                }
                if (error.response.data.path) {
                    errorPath = error.response.data.path;
                }
            }
            this.pathNotificacion = errorPath;
            this.mensajeNotificacion = errorMessage;
            this.mostrarNotificacion = true;

            // Cierra la notificación después de 5 segundos
            setTimeout(() => {
                this.cerrarNotificacion();
            }, 5000);
        },
    },
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.filtros {
    display: flex;
    flex-grow: 1;
}

.error-texto {
    color: #ff5252;
    font-size: smaller;
}

.mensaje {
    text-align: center;
    padding-top: 40px !important;
}

.mensaje em {
    font-size: medium;
    color: rgb(161 161 162);
}

.mensaje:hover {
    background-color: white;
}

.icono {
    font-size: 50px;
}

.mensaje-error {
    margin-top: 0px;
    padding: 0px 0px;
}

::v-deep .estiloFilas div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .estiloFilas div table thead tr th span {
    font-weight: bold;
    color: black !important;
}

.estiloFilas tbody tr td {
    font-size: 14px !important;
}

::v-deep .estiloFilas .v-data-footer {
    width: 100% !important;
}

::v-deep .estiloFilas .v-data-footer__select .v-select {
    margin: 5px;
    margin-left: 10px;
}

::v-deep .estiloFilas .v-data-table__wrapper {
    border: 1px solid #f7f6f6;
}

.notificacion {
    position: fixed;
    top: 50px;
    right: 20px;
    padding: 15px;
    border-radius: 5px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
    max-width: 30vw;
}

.notificacion span:last-child {
    cursor: pointer;
    margin-right: 10px;
    padding-top: 3px;
    width: 80%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.rotate-animation-notification {
    animation: rotate-notification 1s ease-in-out; 
}

@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}

.size {
    font-size: xxx-large;
    font-weight: bold;
    color: #f80505;
}

.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
</style>
  