<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main class="main-bodega">
        <div>
            <ValidationProvider ref="provider" v-slot="{ errors }" :rules="{ required: true }">       
                <v-autocomplete id="bodega" v-model="bodega" label="Bodegas" dense outlined 
                    :error-messages="errorBodega || errors" :items="listaBodegas" no-data-text="Sin resultados"
                    class="autocomplete-bodega" :menu-props="{ maxHeight: 170, offsetY: true}">
                </v-autocomplete>
            </ValidationProvider>
        </div>

        <!-- Contenedor para mostrar los mensajes de error -->
        <div v-if="mostrarNotificacion" class="notificacion advertencia">
            <span><v-icon color="#f80505" class="rotate-animation-notification size">highlight_off</v-icon></span>
            <span>{{ mensajeNotificacion }} <br> {{ pathNotificacion }}</span>
        </div>
    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationProvider, } from 'vee-validate';
import { mapState } from "vuex";
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'Este campo es requerido',
});

export default {
    components: {
        ValidationProvider,
    },

    /**
    * Comentario explicando la funcionalidad de elegir uno o dos parámetros de búsqueda
    * Componente para seleccionar una bodega relacionada.
    * @prop {Object} parametrosDeBusqueda - Parámetros de búsqueda. Permite configurar qué parámetros se
    * utilizarán en la búsqueda.
    * - codBode: Indica si se utilizará el código de bodega en la búsqueda.
    * - nomBode: Indica si se utilizará el nombre de bodega en la búsqueda.
    * Si la definición está así :parametrosDeBusqueda="{ codBode: '', nomBode: '' }" se ejecutará la búsqueda por ambos parámetros.
    * Si se coloca codBode:'valor1', se deberá colocar nomBode: '', de este modo buscará sólo por codBode.
    * Si se coloca nomBode: 'valor2', se deberá colocar codBode:'', de este modo buscará sólo por nomBode.
    */
    props: {
        parametrosDeBusqueda: {
            type: Object,
            required: true,
        },
        codBodeSeleccionado: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            codBodeSeleccionadoLocal: this.codBodeSeleccionado,
            mostrarNotificacion: false,
            mensajeNotificacion: "",
            pathNotificacion: "",
            bodega: null,
            listaBodegas: [],
            errorBodega: '',
        };
    },
 
    watch: {
        'bodega': function(newValue, oldValue) {
            const bodegaVacia = newValue === null || newValue === '' ? true : false;
            const bodegaAnerior = oldValue === null || oldValue === '' ? true : false;
            if (bodegaVacia && !bodegaAnerior) {
                this.$emit('campo-vacio');
            }
            if (!bodegaVacia) {
                this.verificarCondiciones();
            } else {
                this.$emit('bodega-seleccionada', null);
            }
        }
    },

    created() {
        setTimeout(() => {
            this.buscarBodega();
        }, 1500);
    },

    computed: {
        ...mapState(["enterprise"]),
    },

    methods: {

        /**
         * Busca las bodegas por la empresa 
         * y almacena los valores de la respuesta en un array
         */
        async buscarBodega() {
            try {
                const params = {
                    codEmpr: this.enterprise.code, 
                };
                const response = await this.$http.get('msa-administration/inBodeg/listarBodegas', { params });
                this.listaBodegas = Array.isArray(response.data) ? response.data.map(item => ({
                    text: `${item.codBode} - ${item.nomBode.trim()}`,
                    value: `${item.codBode}`,
                    estado: `${item.estActi}`,
                })) : [];
            } catch (error) {
                this.verError(error);
            }
        },

        /**
         * Verifica el estado y las condiciones para relacionar la bodega a un producto
         * Si cumple las condiciones se emite el código de la bodega seleccionada, 
         * de lo contrario se emite un valor null y la condición no cumplida
         */
        async verificarCondiciones() {
            if (this.listaBodegas.length > 0) {
                let bodegaActual = this.listaBodegas.find(bodega => bodega.value === this.bodega);
                if (typeof bodegaActual === 'object') {
                    if (bodegaActual.estado === 'N') {
                        this.errorBodega = 'La bodega esta inactiva.';
                        this.$emit('bodega-seleccionada', null);
                    } else {
                        try {
                            const response = await this.$http.get('msa-administration/inRbous/validarCondicion', {
                                params: {
                                    codBode: `${this.bodega}`,
                                    codEmpr: this.enterprise.code,
                                },
                            });
                            if (response.data === 'La bodega no esta relacionada al usuario WASOLUTI') {
                                this.errorBodega = 'La bodega no esta relacionada al usuario WASOLUTI.';
                                this.$emit('bodega-seleccionada', null);
                            } else {
                                this.errorBodega = '';
                                this.$emit('bodega-seleccionada', parseInt(this.bodega));
                            }
                        } catch (error) {
                            this.verError(error);
                        }
                    }
                }
            }
        },

        /**
        * Reinicia el texto de búsqueda.
        */
        restablecerTextDeBusqueda() {
            this.bodega = null;
            this.errorBodega = '';
        },

        /**
         * Cierra la notificación del error
         */
        cerrarNotificacion() {
            this.mostrarNotificacion = false;
            this.mensajeNotificacion = "";
            this.pathNotificacion = "";
            this.tipoNotificacion = "";
        },

        /**
         * Muestra el error en una notificación correspondiente.
         * 
         * @param {*} error - Objeto de error.
         */
        verError(error) {
            
            let errorMessage = "Error desconocido, contacta con el administrador.";
            let errorPath = "";

            if (error.response && error.response.data) {
                if (error.response.data.mensaje && Array.isArray(error.response.data.mensaje)) {
                    errorMessage = error.response.data.mensaje[0];
                } else if (error.response.data.error) {
                    errorMessage = error.response.data.error;
                }
                if (error.response.data.path) {
                    errorPath = error.response.data.path;
                }
            }
            this.pathNotificacion = errorPath;
            this.mensajeNotificacion = errorMessage;
            this.mostrarNotificacion = true;

            // Cierra la notificación después de 5 segundos
            setTimeout(() => {
                this.cerrarNotificacion();
            }, 5000);
        },
    },
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.main-bodega {
    padding: 0 0rem !important;
}

.autocomplete-bodega{
    width: 100%;
}

.notificacion {
    position: fixed;
    top: 50px;
    right: 20px;
    padding: 15px;
    border-radius: 5px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
    max-width: 30vw;
}

.notificacion span:last-child {
    cursor: pointer;
    margin-right: 10px;
    padding-top: 3px;
    width: 80%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.rotate-animation-notification {
    animation: rotate-notification 1s ease-in-out; 
}

@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}

.size {
    font-size: xxx-large;
    font-weight: bold;
    color: #f80505;
}

.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
</style>